<template>
  <div class="home">
    
    <div class="div_content3">
      <img src="../assets/oval1.png" class="oval-bg1">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <div>
                    <label class="text-krub text-42" style="color:#fff">Alamat</label>
                    <p class="text-krub-reg text-14" style="color:#fff">
                        {{alamat}}
                    </p>
                </div>
                <div>
                    <label class="text-krub text-42" style="color:#fff">Sosial Media</label>
                    <b-col sm="12" style="margin-top:40px;">
                        <a :href="link_fb" target="_blank">
                            <img src="../assets/fb-icon.png" class="pointer-select" style="width:60px;margin-right:15px" >
                        </a>
                        <a :href="link_ig" target="_blank">
                            <img src="../assets/ig-icon.png" class="pointer-select" style="width:60px;margin-right:15px" >
                        </a>
                        <a :href="link_youtube" target="_blank">
                            <img src="../assets/yt-icon.png" class="pointer-select" style="width:60px;margin-right:15px" >
                        </a>
                     </b-col>
                    
                     <b-col sm="12" style="margin-top:20px;">
                        <!-- <label class="icon" style=""></label> -->
                        <a :href="contact_tokped" target="_blank">
                            <img src="../assets/shopee-icon.png" class="pointer-select" style="width:60px;margin-right:15px" >
                        </a>
                        <a :href="contact_tokped" target="_blank">
                            <img src="../assets/tokped-icon.png" class="pointer-select" style="width:60px;margin-right:15px" >
                        </a>
                     </b-col>
                </div>
              </b-col>
              <b-col sm="6" class="image-person1" >
                <img src="../assets/person1.png" style="width:60%">
              </b-col>
            </b-row>
            
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    <div class="div_content6">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" >
            
            <b-row>
              <b-col sm="6" class="pertantaan-div">
                <img src="../assets/person3.png" class="person3-img">
              </b-col>
              <b-col sm="6" style="text-align:center">
                <b-row>
                  <b-col sm="12">
                    <label class="text-krub text-bold text-60" 
                    style="color:#fff;margin-top:100px">PERTANYAAN</label><br>
                    <label class="text-42 text-krub-reg" style="margin-left:15px;color:#c5c178">isi formulir berikut</label><br>
                    <label class="text-14 text-krub" style="color:#fff;text-align:left">
                      Kami senang mendengar tanggapan, pertanyaan, atau masukan dari Anda. Jika Anda memiliki pertanyaan tentang produk atau pelayanan kami, silakan hubungi tim layanan pelanggan kami melalui formulir kontak yang tersedia di halaman ini.
                    </label>
                  </b-col>
                  <b-col sm="12">
                    <input type="text" class="form-control form-pertanyaan" v-model="name" placeholder="NAME">
                    <input type="text" class="form-control form-pertanyaan" v-model="email" placeholder="MAIL">
                    <!-- <input type="text" class="form-control form-pertanyaan" placeholder="MESSAGE"> -->
                    <textarea class="form-control form-pertanyaan" rows="7" v-model="pesan" placeholder="MESSAGE"></textarea>
                    <b-button class="button-biru text-krub" style="margin-top:45px" @click="sendPesan()">SEND MESSAGE</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Footer from '@/views/Footer.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
export default {
  name: 'Home',
  components: {
    Footer
    // HelloWorld
  },
  data() {
      return {
        contact_tokped:'',
        contact_shopee:'',
        link_fb:'',
        link_ig:'',
        link_youtube:'',
        youtube_embed:'',
        alamat:'',
        addMail:'',
        name:'',
        email:'',
        pesan:'',
        
      }
    },
    mounted(){
        const vm = this;
        
        Vue.axios({
            method: 'get',
            url: this.$urlApi+'company_info',
            headers: { 'content-type': 'application/json' },
            // data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.link_fb = result.link_fb;
                vm.link_ig = result.link_ig;
                vm.link_youtube = result.link_youtube;
                vm.contact_shopee = result.link_shopee;
                vm.contact_tokped = result.link_tokped;
                vm.youtube_embed = result.youtube_embed;
                vm.alamat = result.alamat;
            }
        });
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
      gotoProduk(){
        alert('comming soon')
      }
    }
}
</script>
<style>
@font-face {
   font-family: myFirstFont;
   src: url("../assets/font/BStyle_B.ttf");
}
@font-face {
   font-family: Montserrat;
   src: url("../assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
   font-family: Oswald;
   src: url("../assets/font/Oswald-VariableFont_wght.ttf");
}
@font-face {
   font-family: Krub;
   src: url("../assets/font/Krub-Bold.ttf");
}
@font-face {
   font-family: KrubReg;
   src: url("../assets/font/Krub-Regular.ttf");
}
.pointer-select
{
  cursor: pointer;
}
.arrow-vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(270deg);
  top: 40%;
  position: absolute;
}
.arrow-vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(90deg);
  top: 55%;
  position: absolute;
  right: 0;
}
.vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-left2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 30%;
  right: 0;
  position: absolute;
}
.vertical-right2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 40%;
  right: 0;
  position: absolute;
}
.form-pertanyaan
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #9172d1 !important;
  border-radius: 0 !important;
  color:#fff !important;
}
.form-newsletter
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 0 !important;
  color:#000 !important;
}
/* .person3-img
{
  width: 600px;
  position: absolute;
} */
.pertantaan-div
{
  display: block;
  position: relative;
  height: 400px;
}
.pertantaan-div img
{
  width: 95%;
  position: absolute;
}
.image-person1 img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.oval-bg1
{
  position: absolute;
}
.oval-bg2
{
  margin-top: -200px;
  position: absolute;
  right: 0;
}
.button-biru
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.button-biru-left
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
}
.border-outline
{
  border:1px solid #fff;
  padding:25px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.number-book
{
  position: absolute;
  background-color:#fff ;
  padding:10px;
  margin-top: -25px;
  margin-left: -25px;
}
.border-outline img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-h{
  font-family: myFirstFont;
  font-size: 40px;
}
.text-krub{
  font-family: Krub;
}
.text-krub-reg{
  font-family: KrubReg;
}
.text-oswald{
  font-family: Oswald;
}
.text-monts{
  font-family: Montserrat;
}
.text-42{
  font-size: 42px;
}
.text-60{
  font-size: 60px;
}
.text-14{
  font-size: 14px;
}
.text-24{
  font-size: 24px;
}
.text-bold
{
  font-weight: bolder;
}
.text-m{
  font-size: 20px;
}
.div_content1
{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #000;
    color: #fff;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */

}
.div_content2
{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    position: relative;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content3
{
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content4
{
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #7b368b;
    position: relative;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content5
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content6
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* height: 850px; */
}
.div_content7
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #000;
    color: #fff;
    /* height: 850px; */
}
.image-oval4
{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
}
@media only screen and (max-width: 600px) {
  .image-oval4
  {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
  }
  .vertical-left2
  {
    display: none;
  }
  .vertical-right2
  {
    display: none;
  }
}
</style>