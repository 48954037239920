<template>
  <div class="home">
    
    <div class="div_content3">
      <img src="../assets/oval1.png" class="oval-bg1">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-list-group @click="eventDetail(event.id)" class="event-list" v-for="(event) in list_event" :key="event.id" style="cursor:pointer">
                <b-list-group-item style="margin-bottom:20px">
                    <b-row>
                        <b-col sm="4" class="image-event">
                            <img :src="'data:image/png;base64,'+event.image" style="width:100%">
                        </b-col>
                         <b-col sm="8">
                                <div style="padding-top:40px;padding-left:40px">
                                    <h4 class="text-krub text-42">{{event.name}}</h4>
                                </div>
                                <div style="padding-left:40px;padding-right:40px;padding-bottom:40px">
                                    <!-- {{getContent(event.content)}} -->
                                    <span v-html="getContent(event.content)"></span>
                                </div>
                         </b-col>
                    </b-row>
                </b-list-group-item>
                
            </b-list-group>
            
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    
    <Footer />
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Footer from '@/views/Footer.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
export default {
  name: 'Home',
  components: {
    Footer
    // HelloWorld
  },
  data() {
      return {
        list_event: [],
        perpage: 10,
        offset:0,
        currentPage:1
      }
    },
    mounted(){
        const vm = this;
        const req_post = {
            perpage:this.perpage,
            offset:this.offset
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_event',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_event = result.list_event;
            }
        });
    },
    methods: {
        getContent(value){
            const content = value.substr(0,1000);
            console.log(content)
            return content+'</p>';
        },
      eventDetail(data_id) {
        this.$router.push({ path: '/event-detail',query:{data_id:data_id}}); 
      },
      
    }
}
</script>
<style>
@font-face {
   font-family: myFirstFont;
   src: url("../assets/font/BStyle_B.ttf");
}
@font-face {
   font-family: Montserrat;
   src: url("../assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
   font-family: Oswald;
   src: url("../assets/font/Oswald-VariableFont_wght.ttf");
}
@font-face {
   font-family: Krub;
   src: url("../assets/font/Krub-Bold.ttf");
}
@font-face {
   font-family: KrubReg;
   src: url("../assets/font/Krub-Regular.ttf");
}
.list-group-item:hover
{
    background-color: #cdcdcd;
}
.image-event
{
    display: flex;
    justify-content: center;
}
.image-event img
{
    display: block;
    margin: auto;
}
.pointer-select
{
  cursor: pointer;
}
.arrow-vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(270deg);
  top: 40%;
  position: absolute;
}
.arrow-vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(90deg);
  top: 55%;
  position: absolute;
  right: 0;
}
.vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-left2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 30%;
  right: 0;
  position: absolute;
}
.vertical-right2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 40%;
  right: 0;
  position: absolute;
}
.form-pertanyaan
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #9172d1 !important;
  border-radius: 0 !important;
  color:#fff !important;
}
.form-newsletter
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 0 !important;
  color:#000 !important;
}
/* .person3-img
{
  width: 600px;
  position: absolute;
} */
.pertantaan-div
{
  display: block;
  position: relative;
  height: 400px;
}
.pertantaan-div img
{
  width: 95%;
  position: absolute;
}
.image-person1 img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.oval-bg1
{
  position: absolute;
}
.oval-bg2
{
  margin-top: -200px;
  position: absolute;
  right: 0;
}
.button-biru
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.button-biru-left
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
}
.border-outline
{
  border:1px solid #fff;
  padding:25px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.number-book
{
  position: absolute;
  background-color:#fff ;
  padding:10px;
  margin-top: -25px;
  margin-left: -25px;
}
.border-outline img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-h{
  font-family: myFirstFont;
  font-size: 40px;
}
.text-krub{
  font-family: Krub;
}
.text-krub-reg{
  font-family: KrubReg;
}
.text-oswald{
  font-family: Oswald;
}
.text-monts{
  font-family: Montserrat;
}
.text-42{
  font-size: 42px;
}
.text-60{
  font-size: 60px;
}
.text-14{
  font-size: 14px;
}
.text-24{
  font-size: 24px;
}
.text-bold
{
  font-weight: bolder;
}
.text-m{
  font-size: 20px;
}
.div_content1
{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #000;
    color: #fff;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */

}
.div_content2
{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    position: relative;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content3
{
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content4
{
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #7b368b;
    position: relative;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content5
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content6
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* height: 850px; */
}
.div_content7
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #000;
    color: #fff;
    /* height: 850px; */
}
.image-oval4
{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
}
@media only screen and (max-width: 600px) {
  .image-oval4
  {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
  }
  .vertical-left2
  {
    display: none;
  }
  .vertical-right2
  {
    display: none;
  }
}
</style>